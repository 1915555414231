import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {RouteLite, EmployeeLite} from 'app/blocks/model/site-visit.model';

export class VehicleLite {
    id: number;
    vehicleId: string;
    description: string;
    licencePlate: string;
    vehicleTypeDesc: string;
    maxWeight: number;
    maxPackageCount: number;

    constructor(vehicle?: any) {
        if (vehicle) {
            this.id = vehicle.id;
            this.vehicleId = vehicle.vehicleId;
            this.description = vehicle.description;
            this.licencePlate = vehicle.licencePlate;
            this.vehicleTypeDesc = vehicle.vehicleTypeDesc;
            this.maxWeight = vehicle.maxWeight;
            this.maxPackageCount = vehicle.maxPackageCount;
        }
    }
}

export interface IVehicleInspectionReport extends IBaseEntity {
    id?: number;
    inspectionDate?: string;
    route?: RouteLite;
    vehicle?: VehicleLite;
    inspectedBy?: EmployeeLite;
    reportStatus?: string;
    pdfAvailable?: boolean;
    type?: string;
}

export class VehicleInspectionReport extends BaseEntity implements IVehicleInspectionReport {
    public id?: number;
    public inspectionDate?: string;
    public route?: RouteLite;
    public vehicle?: VehicleLite;
    public inspectedBy?: EmployeeLite;
    public reportStatus?: string;
    public pdfAvailable?: boolean;
    public type?: string;

    constructor(vehicleInspectionReport?: any) {
        super();
        if (vehicleInspectionReport) {
            this.id = vehicleInspectionReport.id;
            this.inspectedBy = vehicleInspectionReport.inspectedBy;
            this.inspectionDate = vehicleInspectionReport.inspectionDate;
            this.route = vehicleInspectionReport.route;
            this.vehicle = vehicleInspectionReport.vehicle;
            this.reportStatus = vehicleInspectionReport.reportStatus;
            this.pdfAvailable = vehicleInspectionReport.pdfAvailable;
            this.type = vehicleInspectionReport.type;
        }
    }
    get discriminator(): any {
        return this.id;
    }

    clone(): IVehicleInspectionReport {
        return new VehicleInspectionReport(this);
    }
}
